import { Controller } from '@hotwired/stimulus'
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'

declare const I18n
const firebaseApp = initializeApp((window as any).firebaseConfig)

export default class extends Controller {
  static values = { uid: String }
  static targets = ['name', 'email', 'password', 'alert']

  declare uidValue: string
  declare hasNameTarget: boolean
  declare nameTarget: HTMLInputElement
  declare emailTarget: HTMLInputElement
  declare passwordTarget: HTMLInputElement
  declare alertTarget: HTMLElement
  creatingUser: boolean

  connect() {
    // Initiates Firebase auth and listen to auth state changes.
    onAuthStateChanged(getAuth(firebaseApp), this.onAuthStateChanged.bind(this))
  }

  onAuthStateChanged(user) {
    if (!user) return

    if (!user.emailVerified && !this.creatingUser) {
      if (window.location.pathname !== '/authentication/verify_email')
        window.location.href = '/authentication/verify_email'
    } else if (!this.uidValue) {
      // Sign in the user in the backend
      getAuth(firebaseApp)
          .currentUser.getIdToken(true)
          .then((idToken) => {
            window.location.href = `/?token=${encodeURIComponent(idToken)}`
          })
          .catch((error) => console.log(error))
    }
  }

  async signOut() {
    await signOut(getAuth(firebaseApp))
    window.location.href = '/authentication/sign_out_user'
  }

  signInPassword(event) {
    event.preventDefault()
    const email = this.emailTarget.value
    const password = this.passwordTarget.value

    return this.signInWithEmailAndPassword(email, password, false)
  }

  async createWithPassword(event) {
    event.preventDefault()
    const email = this.emailTarget.value
    const password = this.passwordTarget.value

    try {
      // We set creatingUser to true or else onAuthStateChanged will redirect us to verified_email before
      // createUserWithEmailAndPassword returns.
      this.creatingUser = true
      await createUserWithEmailAndPassword(getAuth(firebaseApp), email, password)
      let user = getAuth(firebaseApp).currentUser
      if (this.hasNameTarget) {
        await updateProfile(user, { displayName: this.nameTarget.value })
      }
      document.cookie = `verify_email=${email}`
      await this.sendEmailVerification()
      this.creatingUser = false
    } catch (error) {
      console.log(error)
      switch (error.code) {
        case 'auth/email-already-in-use':
          return this.signInWithEmailAndPassword(email, password, true)
        default:
          this.setAlert(error.message)
      }
    }
  }

  signInWithEmailAndPassword(email, password, registering) {
    signInWithEmailAndPassword(getAuth(firebaseApp), email, password).catch((error) => {
      console.log(error)
      switch (error.code) {
        case 'auth/user-not-found':
          this.setAlert(I18n.t('authentication.user_not_found'))
          break
        case 'auth/wrong-password':
          if (registering)
            this.setAlert(I18n.t('authentication.email_in_use'))
          else
            this.setAlert(I18n.t('authentication.wrong_password'))
          break
        case 'auth/network-request-failed':
          this.setAlert(I18n.t('authentication.network_request_failed'))
          break
        case 'auth/account-exists-with-different-credential':
          this.setAlert(I18n.t('authentication.account-exists-with-different-credential.email'))
          break
        default:
          this.setAlert(error.message)
      }
    })
  }

  signInGoogle() {
    // Sign in Firebase using popup auth and Google as the identity provider.
    signInWithPopup(getAuth(firebaseApp), new GoogleAuthProvider()).catch((error) => {
      console.log(error)
      switch (error.code) {
        case 'auth/account-exists-with-different-credential':
          this.setAlert(I18n.t('authentication.account-exists-with-different-credential.google'))
          break
        default:
          this.setAlert(error.message)
      }
    })
  }

  signInFacebook() {
    signInWithPopup(getAuth(firebaseApp), new FacebookAuthProvider()).catch((error) => {
      console.log(error)
      switch (error.code) {
        case 'auth/account-exists-with-different-credential':
          this.setAlert(I18n.t('authentication.account-exists-with-different-credential.facebook'))
          break
        default:
          this.setAlert(error.message)
      }
    })
  }

  async resetPassword(event) {
    event.preventDefault()
    await sendPasswordResetEmail(getAuth(firebaseApp), this.emailTarget.value)
      .catch(error => this.setAlert(error.message))
    this.setAlert(I18n.t('authentication.please_check_email_for_password_reset'), 'info')
  }

  async sendEmailVerification() {
    await sendEmailVerification(getAuth(firebaseApp).currentUser).catch((error) => console.log(error))
    if (window.location.pathname !== '/authentication/verify_email')
      window.location.href = '/authentication/verify_email'
  }

  private setAlert(message, type = 'warning') {
    this.alertTarget.innerHTML = `
      <div class='alert-box alert alert-${type} alert-dismissible fade show' role='alert'>
        <button class='close' data-dismiss="alert" type="button" aria-label="Close"> 
            <span aria-hidden="true">&times;</span>
        </button>  
        <span>${message}</span>        
      </div>`
  }
}
